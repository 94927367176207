import { getFeedForwards } from "./discussion.api";
import { httpClient, cachedHttpClient } from "./httpClient";

const getQRLetter = (user_id) => httpClient.get(`/info/qrletter/${user_id}`, { responseType: "arraybuffer" });

const getTeams = () => httpClient.get("/teams");
const getEscalateAdmins = () => httpClient.get("/members/admins");
const getPastSurveys = () => httpClient.get("/surveys?status=past", { cache: { cleanupInterval: 200} });
const getDelegatedTasks = () => httpClient.get("/delegatedTasks");

// specific survey overview: feedforwards, tasks etc
const getDiscussionReport = (survey_id) =>
    httpClient.get(`/reports/${survey_id}/discussion`);
const exportDiscussionReport = (survey_id) =>
    httpClient.get(`/reports/${survey_id}/discussionFile`, {
        timeout: 300000,
        responseType: "arraybuffer",
    });

// powerpoint presentation
const exportPresentation = (survey_id) =>
    httpClient.get(`/reports/${survey_id}/summaryFile`, {
        timeout: 300000,
        responseType: "arraybuffer",
    });

// specific survey overview: question avg & deviation
const getMatrixReport = (survey_id) =>
    httpClient.get(`/reports/${survey_id}/matrix`);
const exportMatrixReport = (survey_id) =>
    httpClient.get(`/reports/${survey_id}/matrixFile`, {
        timeout: 300000,
        responseType: "arraybuffer",
    });

// dashboard survey summary at company level
const getSurveySummaryForCompany = (survey_id) =>
    cachedHttpClient.get(`/dashboard/${survey_id}/company`);

//filtered tag report
const getTagReport = (survey_id, tags) => {
    let params = new URLSearchParams();
    tags.forEach((tag) => params.append("tags", tag));

    return cachedHttpClient.get(`/dashboard/${survey_id}?${params.toString()}`);
};
//Only use normal tags, and the enitityIdes
const getTagReportByEntityIds = (survey_id, tags, entityIds) => {
    let params = new URLSearchParams();
    tags.forEach((tag) => params.append("tags", tag));
    entityIds.forEach((entityId) => params.append("entityIds", entityId));

    return cachedHttpClient.get(`/dashboard/${survey_id}?${params.toString()}`);
};

// specific survey overview: question avg & deviation
const getEnpsReport = (survey_id) =>
    httpClient.get(`/reports/${survey_id}/enps`);

const exportEnpsReport = (survey_id) =>
    httpClient.get(`/reports/${survey_id}/enpsFile`, {
        timeout: 300000,
        responseType: "arraybuffer",
    });

const getSurveySettings = (survey_id) =>
    httpClient.get(`/reports/${survey_id}`);

const exportMasterFile = () =>
    httpClient.get(`/info/export?format=${6}`, { responseType: "arraybuffer" });

const exportProcessDataFile = () =>
    httpClient.get(`/info/processData`, { responseType: "arraybuffer", headers: { 'Accept': 'application/vnd.ms-excel' }});

const getSuperAdmins = () => httpClient.get(`/superadmins`);
const removeSuperAdmin = (user_id) => httpClient.delete(`/superadmins/${user_id}`);
const addSuperAdmin = (user_id) => httpClient.put(`/superadmins/${user_id}`);

const getSurveyCalendar = () => httpClient.get(`/interval`);
const setSurveyCalendar = (settings) => httpClient.put(`/interval`, settings);
const calcSurveyCalendar = (surveyDate, surveyInterval) =>
    httpClient.get(`/interval/calc?date=${surveyDate}&interval=${surveyInterval}`);
const endSurvey = () => httpClient.put(`/interval/end`);


export {
    getSuperAdmins,
    removeSuperAdmin,
    addSuperAdmin,
    getTeams,
    getEscalateAdmins,
    getPastSurveys,
    getDelegatedTasks,
    getDiscussionReport,
    exportDiscussionReport,
    exportPresentation,
    getSurveySummaryForCompany,
    getMatrixReport,
    exportMatrixReport,
    getTagReport,
    getTagReportByEntityIds,
    getEnpsReport,
    exportEnpsReport,
    getSurveySettings,
    getQRLetter,
    exportMasterFile,
    exportProcessDataFile,
    getSurveyCalendar,
    setSurveyCalendar,
    calcSurveyCalendar,
    endSurvey,
};
